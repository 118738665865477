import { graphql } from "gatsby"

import { BlogPost } from "../components/posts"

const BlogTemplate = ({ data: { post }, pageContext }) => {
  return <BlogPost post={post} pageContext={pageContext} />
}

export default BlogTemplate

export const blogTemplateQuery = graphql`
  query BlogTemplateQuery($slug: String) {
    post: blogPost(slug: { eq: $slug }) {
      ...BlogPostFragment
    }
  }
`
